import { Grid, Menu, MenuItem, InputAdornment, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { ALLOWED_EMAIL_DOMAINS } from '../../utils/AllowedEmailDomains';
import { useSelector } from 'react-redux';
import './TeamDetails.css';

const CreateTeamModal = (props) => {
  const user = useSelector((state) => state.generalData.user);
  const [anchorEl, setAnchorEl] = useState(false);
  const [userValue, setUserValue] = useState('');
  const [serviceAccValue, setServiceAccValue] = useState('');
  const [userRole, setUserRole] = useState('');
  const [deleteTMList, setDeleteTMList] = useState([]);
  const [addTMList, setAddTMList] = useState([]);
  const [disabledRole, setDisabledRole] = useState(true);
  const handleCloseMenu = () => {
    setAnchorEl(false);
  };

  const [teamMembers, setTeamMembers] = useState(
    props.team ? (props.team.teamMembers ? props.team.teamMembers : []) : []
  );
  let tempTMList = teamMembers;
  const [teamMember, setTeamMember] = useState({});
  const [userId, setUserId] = useState('');
  const [team, setTeam] = useState({
    teamName: props.team ? props.team.teamName : '',
    description: props.team ? props.team.description : '',
    teamMembers: props.team ? (props.team.teamMembers ? props.team.teamMembers : []) : []
  });
  const [disableSave, setDisableSave] = useState(true);
  const handleSave = () => {
    let common = addTMList.filter(function (addTM) {
      return deleteTMList.some(function (deleteTM) {
        return addTM.userId === deleteTM.userId;
      });
    });
    let addTMListTemp = addTMList.filter(function (addTM) {
      return !common.some(function (commonTM) {
        return addTM.userId === commonTM.userId;
      });
    });
    let delTMListTemp = deleteTMList.filter(function (deleteTM) {
      return !common.some(function (commonTM) {
        return deleteTM.userId === commonTM.userId;
      });
    });
    props.handleSave(team, addTMListTemp, delTMListTemp);
  };

  const handleClose = () => {
    setServiceAccValue('');
    setUserValue('');
    setTeamMember({});
    setTeamMembers(props.team.teamMembers);
    setDeleteTMList([]);
    setAddTMList([]);
    setUserRole('');
    setDisabledRole(true);
    props.handleClose();
  };

  const handleMenu = () => {
    setAnchorEl(true);
  };

  useEffect(() => {
    if (props.team != undefined) {
      setTeam({
        teamName: props.team.teamName,
        description: props.team.description,
        teamMembers: props.team.teamMembers
      });
      setTeamMembers(props.team.teamMembers);
      setAddTMList([]);
      setDeleteTMList([]);
    }
  }, [props.team]);

  useEffect(() => {
    if (teamMember.userId !== undefined && teamMember.userId.length > 0) {
      if (!teamMembers.some((el) => el.userId === teamMember.userId)) {
        tempTMList = [...tempTMList, teamMember];
        setTeamMembers(tempTMList);
        setAddTMList([...addTMList, teamMember]);
        setUserRole('');
      }
    }
  }, [teamMember]);

  useEffect(() => {
    setTeamMember({
      ...teamMember,
      userType: 'USER',
      userId: userId,
      userRole: userRole
    });
  }, [userRole]);

  useEffect(() => {
    const disable = team.teamName.length == 0 || team.description.length == 0;
    setDisableSave(disable);
  }, [team]);

  useEffect(() => {
    setServiceAccValue('');
    setTeam({ ...team, teamMembers: teamMembers });
  }, [teamMembers]);

  function validateEmail(email) {
    const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (email == null || !regexp.test(email)) {
      return false;
    }
    const domainName = email.split('@')[1];
    if (ALLOWED_EMAIL_DOMAINS.filter((e) => e == domainName.toLowerCase()).length == 0) {
      return false;
    }
    return true;
  }

  const handleRole = (e) => {
    setUserRole(e.target.outerText);
    setUserValue('');
    setDisabledRole(true);
  };

  const handleDelete = (_e, userId) => {
    let newTMList = teamMembers.filter((tm) => tm.userId !== userId);
    let tempDeleteTMList = [...deleteTMList, teamMembers.filter((tm) => tm.userId === userId)[0]];
    setDeleteTMList(tempDeleteTMList);
    setTeamMembers(newTMList);
  };

  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText={props.title == 'Create New Team' ? 'Create Team' : null}
      handleSave={handleSave}
      handleClose={handleClose}
      disableSave={disableSave}>
      <Grid container>
        <Grid item xs={5.5}>
          <TextField
            required
            margin="dense"
            data-testid="create-team-name"
            label="Team Name"
            type="text"
            fullWidth
            variant="outlined"
            disabled={props.team?.teamName ? true : false}
            defaultValue={props.team ? props.team.teamName : ''}
            onChange={(e) => setTeam({ ...team, teamName: e.target.value })}
          />
          <TextField
            margin="dense"
            data-testid="create-team-desc"
            multiline
            required
            rows={4}
            label="Description"
            fullWidth
            variant="outlined"
            sx={{
              '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
                color: '#353535',
                fontSize: '14px'
              }
            }}
            defaultValue={props.team ? props.team.description : ''}
            onChange={(e) => setTeam({ ...team, description: e.target.value })}
          />
          <Tooltip title="Enter team member email">
            <TextField
              margin="dense"
              data-testid="create-team-member-email"
              label={'Add Users'}
              type="email"
              fullWidth
              value={userValue}
              variant="outlined"
              onChange={(e) => {
                setUserValue(e.target.value);
                if (validateEmail(e.target.value)) {
                  setDisabledRole(false);
                  setUserId(e.target.value);
                } else {
                  setDisabledRole(true);
                  setUserId('');
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      disabled={disabledRole}
                      className="choose-role-button"
                      onClick={handleMenu}>
                      choose role
                    </button>
                  </InputAdornment>
                )
              }}
            />
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="user-role-menu"
            open={anchorEl}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: '31%',
                ml: '-55%',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 8,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <MenuItem disabled onClick={handleRole}>
              Admin
            </MenuItem>
            <MenuItem onClick={handleRole}>Writer</MenuItem>
            <MenuItem disabled onClick={handleRole}>
              Reader
            </MenuItem>
          </Menu>
          <Tooltip title="Feature coming soon...">
            <TextField
              margin="dense"
              data-testid="create-service-account"
              label={'Add Service Accounts'}
              fullWidth
              disabled
              variant="outlined"
              value={serviceAccValue}
              onChange={(e) => {
                setServiceAccValue(e.target.value);
              }}
              onKeyDown={(e) =>
                e.key == 'Enter' &&
                validateEmail(e.target.value) &&
                setTeamMember({
                  ...teamMember,
                  userType: 'SERVICE_PRINCIPAL',
                  userId: e.target.value,
                  userRole: ''
                })
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={6}>
          <Grid container maxHeight="330px" overflow="scroll">
            <Grid item xs={9}>
              <Grid container direction="column">
                <Grid item xs={6}>
                  <b>Team Members</b>
                  {props.title === 'Create New Team' && <div>{user.email}</div>}
                  {teamMembers &&
                    teamMembers.length > 0 &&
                    teamMembers.map((user, index) => <div key={index}>{user.userId}</div>)}
                  <br />
                </Grid>
                <Grid item xs={6}>
                  <b>Service Accounts</b>
                  {teamMembers &&
                    teamMembers.length > 0 &&
                    teamMembers
                      .filter((el) => el.userType === 'SERVICE_PRINCIPAL')
                      .map((user, index) => (
                        <div key={index}>
                          {user.userId}
                          <IconButton onClick={(e) => handleDelete(e, user.userId)}>
                            <img src={DeleteIcon} />
                          </IconButton>
                        </div>
                      ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <b>Role</b>
              {props.title === 'Create New Team' && <div>ADMIN</div>}
              {teamMembers &&
                teamMembers.length > 0 &&
                teamMembers.map((tm, index) => (
                  <div key={index}>
                    {tm.userRole.toUpperCase()}
                    {user.email !== tm.userId && (
                      <IconButton
                        onClick={(e) => handleDelete(e, tm.userId)}
                        sx={{ p: 0, ml: 1, pb: 0.5 }}>
                        <img src={DeleteIcon} />
                      </IconButton>
                    )}
                  </div>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default CreateTeamModal;
