import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import EditIcon from '../../assets/EditIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import CreateTeamModal from './CreateTeamModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTeamMember,
  deleteTeam,
  deleteTeamMember,
  fetchTeamById,
  updateTeam
} from '../../store/slice/team/TeamActions';
import { useParams } from 'react-router-dom';
import { commonActions } from '../../store/slice/CommonSlice';
import { teamActions } from '../../store/slice/team/TeamSlice';
import './TeamDetails.css';
import AppBreadCrumb from '../../components/breadCrumbs/AppBreadCrumb';
import {
  HomeBreadCrumb,
  TeamsBreadCrumb,
  TeamDetailsBreadCrumb
} from '../../utils/BreadCrumbUtils';
import TeamMemberTable from './TeamMemberTable';

const TeamDetails = () => {
  const breadcrumbAr = [HomeBreadCrumb, TeamsBreadCrumb, TeamDetailsBreadCrumb];
  const user = useSelector((state) => state.generalData.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteTMModal, setOpenDeleteTMModal] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState('');

  const onEditTeamBtnClose = () => setOpenEditModal(false);
  const onDeleteTeamBtnClose = () => setOpenDeleteModal(false);
  const onEditTeamBtnClick = () => {
    setOpenEditModal(true);
  };
  const onDeleteTeamBtnClick = () => {
    setOpenDeleteModal(true);
  };
  const onDeleteTeamMemberBtnClick = (e, teamMemberId) => {
    e.preventDefault();
    setTeamMemberId(teamMemberId);
    setOpenDeleteTMModal(true);
  };
  const onDeleteTeamMemberBtnClose = () => {
    setTeamMemberId('');
    setOpenDeleteTMModal(false);
  };

  const { id } = useParams();

  const team = useSelector((state) => state.teamData.team);

  useEffect(() => {
    dispatch(fetchTeamById(id));
  }, []);

  const onSaveTeam = (teamsData, addTMList, deleteTMList) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(updateTeam(id, teamsData)).then(
      () => {
        setOpenEditModal(false);
        dispatch(commonActions.showSuccessAlert(`Team ${teamsData.teamName} updated`));
        dispatch(commonActions.showLoadingIcon(false));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
    {
      addTMList != undefined && addTMList.map((teamMember) => onSaveTeamMember(teamMember));
    }
    {
      deleteTMList.length > 0 &&
        deleteTMList.map((teamMember) =>
          dispatch(deleteTeamMember(teamMember.teamId, teamMember.userId))
        );
    }
    dispatch(fetchTeamById(id));
  };

  const onDeleteTeam = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteTeam(team.id)).then(
      () => {
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Team ${team.teamName} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(teamActions.resetTeam());
        navigate('/teams');
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
        setOpenDeleteModal(false);
        dispatch(commonActions.showErrorAlert('Deletion of team is not allowed'));
      }
    );
  };

  const onSaveTeamMember = (teamMemberData) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(addTeamMember(id, teamMemberData)).then(
      () => {
        dispatch(commonActions.showSuccessAlert(`Team member ${teamMemberData.userId} added`));
        dispatch(commonActions.showLoadingIcon(false));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
    dispatch(fetchTeamById(id));
  };

  const onDeleteTeamMember = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteTeamMember(id, teamMemberId)).then(
      () => {
        setTeamMemberId('');
        setOpenDeleteTMModal(false);
        dispatch(commonActions.showSuccessAlert(`Team member ${teamMemberId} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchTeamById(id));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
  };

  return (
    <div style={{ position: 'relative', minHeight: '75vh' }}>
      <AppBreadCrumb data={breadcrumbAr} />
      {team.teamName.length == 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ paddingLeft: '130px', paddingTop: '20px' }}>
            Loading...
          </Typography>
        </Grid>
      )}
      {team.teamName.length > 0 && (
        <Grid container sx={{ marginTop: '50px', marginLeft: '130px' }}>
          <Grid item xs={12}>
            <div style={{ marginLeft: '60px ' }} className="label">
              TEAM DETAILS
            </div>
            <div style={{ marginLeft: '60px ' }} className="heading_team">
              {team.teamName}
            </div>
            <div style={{ marginLeft: '60px ' }} className="description">
              {team.description}
            </div>
          </Grid>
          <Grid container className="description" my="1.5rem" ml="40px">
            <Button variant="text" data-testid="edit-team-button" onClick={onEditTeamBtnClick}>
              <img src={EditIcon} />
              Edit
            </Button>
            <Button variant="text" data-testid="delete-team-button" onClick={onDeleteTeamBtnClick}>
              <img src={DeleteIcon} />
              Delete
            </Button>
          </Grid>
          <Grid container width="70%" className="table-container-style">
            <Grid
              container
              py={4}
              ml={7}
              sx={{ background: 'rgba(191, 215, 221, 0.2)', borderRadius: '8px' }}>
              <TeamMemberTable
                user={user}
                teamMembers={team.teamMembers}
                onEditTeamBtnClick={onEditTeamBtnClick}
                onDeleteTeamMemberBtnClick={onDeleteTeamMemberBtnClick}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <CreateTeamModal
        open={openEditModal}
        width="md"
        title="Edit Team"
        handleSave={onSaveTeam}
        handleClose={onEditTeamBtnClose}
        team={team}
      />
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Team"
        handleSave={onDeleteTeam}
        saveText="Delete"
        handleClose={onDeleteTeamBtnClose}>
        Are you sure you want to delete this team? This cannot be undone after it is deleted.
      </ConfirmDialog>
      <ConfirmDialog
        open={openDeleteTMModal}
        width="xs"
        title="Delete Team member"
        handleSave={onDeleteTeamMember}
        saveText="Delete"
        handleClose={onDeleteTeamMemberBtnClose}>
        Are you sure you want to delete this team member?
      </ConfirmDialog>
    </div>
  );
};

export default TeamDetails;
