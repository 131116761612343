import axios from 'axios';
import store from '../store/index';
import { commonActions } from '../store/slice/CommonSlice';
export let API_BASE = `${process.env.REACT_APP_BASE_URL}`;
if (API_BASE == undefined) {
  API_BASE = 'https://api-madeye.maersk-digital.dev';
}
const axiosInstance = axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

axiosInstance.interceptors.request.use(
  function (config) {
    if (!window.sessionStorage.getItem('madeye-token')) {
      return {
        ...config,
        signal: AbortSignal.abort()
      };
    }
    config.headers.Authorization = `Bearer ${window.sessionStorage.getItem('madeye-token')}`;
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var status = error.response.status;
    if (status === 401) {
      store.dispatch(commonActions.showErrorAlert('UnAuthorized!!!'));
      sessionStorage.clear();
      window.location.reload();
    }
    if (status === 403) {
      store.dispatch(commonActions.showErrorAlert('Forbidden!!!'));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
